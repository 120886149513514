<template>
  <App
    title="Hand-over"
    left-arrow
  >
    <DynamicForm
      ref="form"
      v-model="model"
      :rules="rules"
      :fieldData="fieldData"
    />
    <Footer
      slot="footer"
      @on-save="onSave"
      @on-cancel="$page.close()"
    />
  </App>
</template>
<script>
import { getOrgList, inventoryList, handoff } from '@/api/order'
import { oppsList } from '@/api/clue'
import DynamicForm from '_c/dynamicForm'
import Footer from '_c/business/footer'
import { Toast } from 'vant'

Toast.allowMultiple()

export default {
  components: {
    DynamicForm,
    Footer
  },
  data () {
    return {
      model: {
        financePlan: '',
        deliveryCenter: '',
        linence: '',
        comment: ''
      },
      rules: {
        deliveryCenter: [
          { required: true, message: '交付中心不能为空', trigger: 'change' }
        ],
        financePlan: [
          { required: true, message: '金融方案不能为空', trigger: 'change' }
        ],
        linence: [
          { required: true, message: '上牌资质不能为空', trigger: 'change' }
        ]
      },
      financePlanList: [
        { value: '1', label: '是' },
        { value: '2', label: '否' },
        { value: '3', label: '待定' }
      ],
      linenceList: [
        { value: '1', label: '正常' },
        { value: '2', label: '暂无' },
        { value: '3', label: '风险' }
      ],
      orderInfo: {},
      deliveryCenterList: []
    }
  },
  computed: {
    fieldData () {
      return [
        {
          data: [
            { label: '交付中心', key: 'deliveryCenter', type: 'select', options: this.deliveryCenterList },
            { label: '金融方案', key: 'financePlan', type: 'select', options: this.financePlanList },
            { label: '上牌资质', key: 'linence', type: 'select', options: this.linenceList },
            { label: '备注', key: 'comment', direction: 'column', props: { type: 'textarea', placeholder: '这里是POC时的备注信息，如果没有则为空' } }
          ]
        }
      ]
    }
  },
  methods: {
    init () {
      /* Toast.loading({
        message: 'Loading...',
        forbidClick: true,
        loadingType: 'spinner'
      }) */
      Promise.all([
        this.getDeliveryCenterList(),
        this.getOppsInfo()
      ]).finally(() => {
        Toast.clear()
      })
    },
    onSave () {
      this.$refs.form.validate().then(valid => {
        if (!valid) return

        const params = {
          channel: this.$storage.get('channel'),
          orderNum: this.orderInfo.orderNum,
          saleDlrId: this.orderInfo.saleDlrId,
          consultantId: this.orderInfo.consultantId,
          consultantName: this.orderInfo.consultantName,
          dhDlrId: this.model.deliveryCenter,
          licenseFlag: this.model.linence,
          loanFlag: this.model.financePlan,
          remark: this.model.comment
        }
        this.$requestHandler(handoff, params).then(res => {
          if (res.success) {
            Toast('Hand-over 成功!')
            this.$router.go(-1)
          } else {
            Toast(res.msg || '提交失败!')
          }
        })
      })
    },
    handOverSuccess () {
      //
    },
    getDeliveryCenterList () {
      const params = {
        channel: this.$storage.get('channel'),
        orgType: 4
      }
      getOrgList(params).then(res => {
        if (res.success) {
          this.deliveryCenterList = res.data.map(item => {
            return {
              label: item.orgName,
              value: item.orgId
            }
          })
        }
      })
    },
    getOppsInfo () {
      const page = {
        page: 1,
        size: 1
      }
      const params = {
        orderNo: this.orderInfo.orderNum
      }
      oppsList(page, params).then(res => {
        if (res.success) {
          const data = res.data.records[0] || {}
          if (data.pocLoanFlag) this.model.financePlan = data.pocLoanFlag
          if (data.pocLicenseFlag) this.model.linence = data.pocLicenseFlag
          if (data.pocRemark) this.model.comment = data.pocRemark
        }
      })
    }
  },
  mounted () {
    this.orderInfo = this.$route.query
    this.init()
  }
}
</script>